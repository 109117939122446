import { createStore } from 'redux';

const INIT_STATE = {
  // user: (Cookies.get('userInfo') ? JSON.parse(Cookies.get('userInfo')) : null),
  // user: (localStorage.getItem('auth.user') ? JSON.parse(localStorage.getItem('auth.user')) : null),
  // loggedIn: !!localStorage.getItem('auth.user'),
  state: 'not_initialized',
  user: {},
};

function reducer(state = INIT_STATE, action) {
  switch (action.type) {
    case 'INITIALIZE':
      return {...state, state: (action.payload.user ? 'logged_in' : 'logged_out'), user: action.payload.user};
    case 'LOGIN':
      return {...state, state: 'logged_in', user: action.payload.user};
    case 'LOGOUT':
      return {...state, state: 'logged_out', user: {}};
    default:
      return state;
  }
}

const store = createStore(reducer)

export default store;