import Default from '../components/Default';
import Personal from '../components/Personal';
import Contacts from '../components/Contacts';
import Medical from '../components/Medical';
import Documents from '../components/Documents';
import Misc from '../components/Misc';

export const pages = [
  ['personal', 'personal', 'Persönliche Daten'],
  ['contacts', 'contacts', 'Notfallkontakte'],
  ['medical', 'medical', 'Medizinische Informationen'],
  ['documents', 'documents', 'Dokumente'],
  ['misc', 'misc', 'Sonstige Informationen'],
];

export const types = {
  default: Default,
  personal: Personal,
  contacts: Contacts,
  medical: Medical,
  documents: Documents,
  misc: Misc,
};