import React, { memo, useState, useCallback, useEffect } from 'react';
import {
  View,
  StyleSheet,
  Image,
  TouchableOpacity,
  ScrollView,
} from 'react-native';
import { useToast } from 'native-base';
import Text from '@notfallkarte/app-ui/src/elements/Text';
import {
  getStatusBarHeight,
  getBottomSpace,
} from 'react-native-iphone-x-helper';
import scale from '@notfallkarte/app-ui/src/utils/scale';
import { Colors, Routes } from '@notfallkarte/app-ui/src/configs';
import InputApp from '@notfallkarte/app-ui/src/elements/InputApp';
import Theme from '@notfallkarte/app-ui/src/style/Theme';
import ButtonLinear from '@notfallkarte/app-ui/src/elements/Buttons/ButtonLinear';
import { useNavigation } from '@react-navigation/native';
import validationEmail from '@notfallkarte/app-ui/src/utils/validation/email';
import { IMAGE } from '@notfallkarte/app-ui/src/images/Image';
import Container from '@notfallkarte/app-ui/src/elements/Layout/Container';
import { useForm, Controller } from 'react-hook-form';
import auth from '@notfallkarte/app-core/utils/auth';
import { authService } from '../../services';
import { useDispatch, useSelector } from 'react-redux'
import AsyncStorage from '@react-native-async-storage/async-storage';

const Login = memo((props) => {
  const toast = useToast();
  const { navigate } = useNavigation();
  const [email, setEmail] = useState('lehieuds@gmail.com');
  const [password, setPassword] = useState('12345678');
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);

  const dispatch = useDispatch();
  const { control, handleSubmit, formState: { errors } } = useForm({ defaultValues: { email: 'stefan@herget.ws', password: '1234567A' } });

  const onSubmit = async (data) => {
    const body = {
      email: data.email,
      password: data.password,
    };

    try {
      const response = await authService.login(body);

      auth.loggedIn = true;
      auth.user = response.user;
      auth.tokens = response.tokens;
      
      AsyncStorage.setItem('auth.user', JSON.stringify(response.user))
      AsyncStorage.setItem('auth.tokens', JSON.stringify(response.tokens))

      dispatch({ type: 'LOGIN', payload: { user: response.user, tokens: response.tokens } });

      navigate('Home');

      toast.show({
        title: 'Ok',
        placement: 'top',
      });
    } catch (error) {
      toast.show({
        title: (error.response?.data?.message ?? error.message),
        placement: 'top',
      });

      throw error;
    }
  };




  const onShowHidePassword = useCallback(() => {
    setVisiblePassword((prev) => !prev);
  }, []);

  const onSignUp = useCallback(() => {
    navigate('Register');
  }, [navigate]);
  const onLogin = useCallback(() => {
    navigate(Routes.MainTab);
  }, []);
  const onForgotPassword = useCallback(() => {
    navigate('ForgotPassword');
  }, [navigate]);

  useEffect(() => {
    const validation = validationEmail(email);
    setIsValidEmail(validation);
  }, [email]);

  const onLogInFacebook = useCallback(async () => {
    ///
  }, []);
  const onLogInTwitter = useCallback(async () => {
    ///
  }, []);

  return (
    <Container style={styles.container}>
      <ScrollView showsVerticalScrollIndicator={false}>
        <View style={styles.logoApp}>
          <Image
            source="/logo-blau.png"
            style={{ width: 150, height: 30, marginBottom: 16 }}
          />
          <Text type="H5" bold>
            Welcome back!
          </Text>
        </View>
        <View style={styles.inputLogin}>
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <InputApp
              title={'E-Mail'}
              value={value}
              onChangeText={(val) => onChange(val)}
              icon={
                <Image
                  source={require('@notfallkarte/app-ui/src/images/Icon/ic_accept.png')}
                  style={styles.icon}
                />
              }
              isShowIcon={isValidEmail}
            />
          )}
          name="email"
          rules={{ required: 'Field is required' }}
          defaultValue=""
        />
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <InputApp
              title={'Passwort'}
              value={value}
              onChangeText={(val) => onChange(val)}
              secureTextEntry={!visiblePassword}
              marginTop={24}
              icon={
                <Image
                  source={require('@notfallkarte/app-ui/src/images/Icon/ic_eye_on.png')}
                  style={styles.icon}
                />
              }
              isShowIcon
              iconPress={onShowHidePassword}
            />
            )}
            name="password"
            rules={{ required: 'Field is required' }}
            defaultValue=""
          />
        </View>
        <ButtonLinear
          white
          title={'Anmelden'}
          onPress={handleSubmit(onSubmit)}
          style={{ marginTop: scale(24) }}
        />
        <TouchableOpacity style={styles.forgot} onPress={onForgotPassword}>
          <Text type="H6" color={Colors.GrayBlue} style={styles.textUnderline}>
            Passwort vergessen ?
          </Text>
        </TouchableOpacity>
        <View style={styles.loginSocial}>
          <Text type="H6" color={Colors.GrayBlue} style={styles.textUnderline}>
            Log in with social account
          </Text>
        </View>
        <View style={styles.frameLoginSocial}>
          <TouchableOpacity
            style={styles.buttonFacebook}
            onPress={onLogInFacebook}
          >
            <Image
              style={{ width: scale(20), height: scale(20) }}
              source={require('@notfallkarte/app-ui/src/images/Icon/ic_facebook.png')}
            />
            <Text
              white
              type="H5"
              color={Colors.White}
              bold
              marginLeft={scale(10)}
            >
              Facebook
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.buttonTwitter}
            onPress={onLogInTwitter}
          >
            <Image
              style={{ width: scale(20), height: scale(20) }}
              source={require('@notfallkarte/app-ui/src/images/Icon/ic_twitter.png')}
            />
            <Text
              white
              type="H5"
              color={Colors.White}
              bold
              marginLeft={scale(10)}
            >
              Twitter
            </Text>
          </TouchableOpacity>
        </View>
        <View style={styles.signUp}>
          <Text type="H6" color={Colors.GrayBlue}>
            Sie haben noch kein Konto ?{' '}
            <Text
              blueLight
              type="H6"
              color={Colors.BlueCrayola}
              semiBold
              onPress={onSignUp}
            >
              Zur Registrierung
            </Text>
          </Text>
        </View>
      </ScrollView>
    </Container>
  );
});

export default Login;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 24,
    paddingBottom: getBottomSpace(),
  },
  logoApp: {
    marginTop: getStatusBarHeight() + scale(36, true),
    alignSelf: 'center',
    alignItems: 'center',
  },
  logo: {
    marginBottom: scale(12, true),
    marginLeft: scale(12),
    width: scale(60),
    height: scale(60),
  },
  inputLogin: {
    marginTop: scale(60, true),
  },
  icon: {
    width: scale(24),
    height: scale(24, true),
  },
  margin24: {
    marginTop: scale(24, true),
  },
  forgot: {
    alignSelf: 'center',
    marginTop: scale(32, true),
  },
  signUp: {
    alignSelf: 'center',
    marginBottom: scale(16, true),
    justifyContent: 'flex-end',
    borderRadius: 16,
    borderColor: Colors.Platinum,
  },
  textUnderline: {
    textDecorationLine: 'underline',
  },
  loginSocial: {
    marginTop: scale(40, true),
    alignItems: 'center',
    justifyContent: 'center',
  },
  frameLoginSocial: {
    marginTop: scale(20, true),
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginBottom: scale(40, true),
  },
  buttonFacebook: {
    flexDirection: 'row',
    marginTop: scale(15, true),
    width: scale(152),
    height: scale(50, true),
    borderRadius: scale(12),
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.bDazzledBlue,
  },
  buttonTwitter: {
    flexDirection: 'row',
    marginTop: scale(15, true),
    width: scale(152),
    height: scale(50, true),
    borderRadius: scale(12),
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.MediumTurquoise,
  },
});
