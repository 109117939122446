import React from 'react';
import { Button, FormControl, Input, useToast } from 'native-base';
import { Link } from '@react-navigation/native';
import { Page } from '@notfallkarte/app-core/components';
import { authService } from '../../services';
import { useForm, Controller } from 'react-hook-form';

function ResetPassword({ navigation }) {
  const toast = useToast();
  const { control, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = async (data) => {
    const body = {
      password: data.password,
    };

    try {
      await authService.resetPassword(document.location.search.substring(7), body);

      navigation.navigate('Start');
    } catch (error) {
      toast.show({
        title: (error.response?.data?.message ?? error.message),
        placement: 'top',
      });
    }
  };

  console.log(document.location.search.substring(7));

  return (
    <Page title="Passwort zurücksetzen">
      <FormControl isRequired isInvalid={'password' in errors}>
        <FormControl.Label>Passwort</FormControl.Label>
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              onBlur={onBlur}
              placeholder="Passwort"
              onChangeText={(val) => onChange(val)}
              value={value}
            />
          )}
          name="password"
          rules={{ required: 'Field is required' }}
          defaultValue=""
        />
        <FormControl.ErrorMessage>
          {errors?.password?.message}
        </FormControl.ErrorMessage>
      </FormControl>
      <FormControl isRequired isInvalid={'password' in errors}>
        <FormControl.Label>Passwort</FormControl.Label>
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              onBlur={onBlur}
              placeholder="Passwort wiederholen"
              onChangeText={(val) => onChange(val)}
              value={value}
            />
          )}
          name="password2"
          rules={{ required: 'Field is required' }}
          defaultValue=""
        />
        <FormControl.ErrorMessage>
          {errors?.password?.message}
        </FormControl.ErrorMessage>
      </FormControl>
      <Button onPress={handleSubmit(onSubmit)}>Absenden</Button>
      <Link to={{ screen: 'Start' }}>Zurück</Link>
    </Page>
  );
};

export default ResetPassword;