import React from 'react';
import { TextArea } from './form';

function Misc() {
  return (
    <>
      <TextArea name="misc" label="Sonstige Informationen" />
    </>
  );
}

export default Misc;