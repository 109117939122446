import Chat from '../pages/Chat';

const linking = {
  Chat: 'chat',
};

const stacks = [
  ['Chat', Chat, 'auth'],
];

export default {
  linking,
  stacks,
};