import React from 'react';
import { FormControl, TextArea } from 'native-base';
import { useFormContext, Controller } from 'react-hook-form';

function Default() {
  const { control, formState: { errors } } = useFormContext();

  return (
    <>
      <FormControl isRequired>
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextArea
              h={20}
              onBlur={onBlur}
              placeholder=""
              onChangeText={(val) => onChange(val)}
              value={value}
            />
          )}
          name="data"
          defaultValue=""
        />
      </FormControl>
    </>
  );
}

export default Default;