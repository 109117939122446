import React from 'react';
import { TextArea, Switch, SwitchGroup, BloodType } from './form';

function Medical() {
  return (
    <>
      <SwitchGroup>
        <Switch name="diabetic" label="Diabetiker" />
        <Switch name="hemophilia" label="Hämophilie" />
        <Switch name="dialysis" label="Dialyse" />
        <Switch name="pregnancy" label="Schwangerschaft" />
        <Switch name="pacemaker" label="Herzschrittmacher" />
      </SwitchGroup>

      <BloodType name="bloodType" label="Blutgruppe" />
      <TextArea name="intolerances" label="Unverträglichkeiten" />
      <TextArea name="preExistingIllness" label="Vorerkrankungen" />
      <TextArea name="medication" label="Medikamente" />
      <TextArea name="allergies" label="Allergien" />
      <TextArea name="familyDoctor" label="Hausarzt" />
      <TextArea name="nursingFacility" label="Pflegeeinrichtung" />
      <TextArea name="misc" label="Sonstiges" />
    </>
  );
}

export default Medical;