import React, {memo} from 'react';
import {View, StyleSheet} from 'react-native';
import Theme from '@notfallkarte/app-ui/src/style/Theme';
import {Routes} from '@notfallkarte/app-ui/src/configs';
import FeatureItem from '@notfallkarte/app-ui/src/components/FeatureItem';
import {IMAGE} from '@notfallkarte/app-ui/src/images/Image';
import Container from '@notfallkarte/app-ui/src/elements/Layout/Container';
import { useDispatch, useSelector } from 'react-redux'
import { authService } from '@notfallkarte/app-account/services';
import { navigate } from '@notfallkarte/app-core/utils/RootNavigation';

const ChatMenu = memo(() => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);

  const mainFeature = [
    {
      img: IMAGE.healthGuide,
      title: 'Persönliche Daten',
      route: 'AccountData',
    },
    {
      img: IMAGE.healthFeed,
      title: 'Passwort ändern',
      route: 'AccountChangePassword',
    },
    {
      img: IMAGE.askFreeQuestion,
      title: 'Einstellungen',
      route: 'AccountSettings',
    },
    {
      img: IMAGE.consultMange,
      title: 'Konto löschen',
      route: 'AccountDelete',
    },
  ];

  return (
    <Container style={styles.container}>
      {mainFeature.map(item => (
        <FeatureItem {...item} key={item.title} />
      ))}
    </Container>
  );
});

export default ChatMenu;

const styles = StyleSheet.create({
  container: {
    ...Theme.flexDirection,
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginTop: 45,
  },
});
