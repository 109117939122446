import History from '../pages/History';

const linking = {
  History: 'history',
};

const stacks = [
  ['History', History, 'auth'],
];

export default {
  linking,
  stacks,
};