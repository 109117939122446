import React, {memo, useState, useCallback} from 'react';
import {View, StyleSheet, ScrollView, Image} from 'react-native';
import { useToast } from 'native-base';
import Text from '@notfallkarte/app-ui/src/elements/Text';
import Theme from '@notfallkarte/app-ui/src/style/Theme';
import scale from '@notfallkarte/app-ui/src/utils/scale';
import {Routes} from '@notfallkarte/app-ui/src/configs';
import InputApp from '@notfallkarte/app-ui/src/elements/InputApp';
import ButtonLinear from '@notfallkarte/app-ui/src/elements/Buttons/ButtonLinear';
import {useNavigation} from '@react-navigation/native';
import AvatarProfile from '@notfallkarte/app-ui/src/components/UpdateProfile/BasicInformation/AvatarProfile';
import {useLayoutEffect} from 'react';
import ButtonIconHeader from '@notfallkarte/app-ui/src/elements/Buttons/ButtonIconHeader';
import Colors from '@notfallkarte/app-ui/src/configs/Colors';
import {ICON} from '@notfallkarte/app-ui/src/images/Icon';
import Layout from '@notfallkarte/app-ui/src/elements/Layout/Layout';
import {useTheme} from '@notfallkarte/app-ui/src/configs/ChangeTheme';
import Container from '@notfallkarte/app-ui/src/elements/Layout/Container';
import { pages, types } from '../../config/data';
import { useForm, FormProvider } from 'react-hook-form';
import { dataService } from '@notfallkarte/app-data/services';

const BasicInformation = memo(({ route, navigation }) => {
  const { id } = route.params;
  const page = pages.find(page => page[0] == id);
  const [firstName, setFirstName] = useState('Devin');
  const [lastName, setLastName] = useState('Sheton');

  const toast = useToast();
  const {navigate, setOptions} = useNavigation();
  const {theme} = useTheme();
  const onGoToOtherInfo = useCallback(() => {
    navigate(Routes.OtherInformation);
  }, [navigate]);
  const onUploadAvatar = useCallback(() => {}, []);
  useLayoutEffect(() => {
    setOptions({
      title: null,
      headerBackground: () => (
        <Container
          style={[styles.headerBackground, {backgroundColor: theme.background}]}
        />
      ),
      headerLeft: () => (
        <ButtonIconHeader marginLeft={24} tintColor={theme.activeTincolor} />
      ),
    });
  }, [setOptions]);

  const fetchData = async () => {
    try {
      var response = await dataService.getData(id);

      if (id === 'contacts') {
        response.data = { contacts: response.data };
      }

      if (id === 'misc') {
        response.data = { misc: response.data };
      }

      return response.data;
    } catch (error) {
      toast.show({
        title: (error.message),
        placement: 'top',
      });
    }
  };

  const methods = useForm({ defaultValues: fetchData });
  const { handleSubmit } = methods;

  const onSubmit = async (data) => {
    try {
      if (id === 'contacts') {
        data = data.contacts;
      }

      if (id === 'misc') {
        data = data.misc;
      }

      const response = await dataService.setData(id, { data });

      toast.show({
        title: 'Daten gespeichert',
        placement: 'top',
      });

      navigation.navigate('Data');
    } catch (error) {
      toast.show({
        title: (error.message),
        placement: 'top',
      });
    }
  };

  const Form = types[page[1]];

  return (
    <Container style={styles.container}>
      <ScrollView showsVerticalScrollIndicator={false}>
        <Text
          size={scale(24)}
          lineHeight={scale(28)}
          bold
          marginTop={scale(16)}>
          {page[2]}
        </Text>
        <Text size={scale(13)} lineHeight={scale(22)} marginTop={scale(16)}>
          Update your profile to get better the answer from
          {'\n'}doctor.
        </Text>

        <FormProvider {...methods}>
          <Form/>
        </FormProvider>

        <ButtonLinear
          white
          title={'Speichern'}
          onPress={handleSubmit(onSubmit)}
          style={styles.buttonLinear}
        />
      </ScrollView>
    </Container>
  );
});

export default BasicInformation;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 24,
  },
  buttonChildren: {
    ...Theme.icons,
    marginLeft: scale(8),
  },
  inputApp: {
    marginTop: scale(24),
  },
  firstName: {
    marginTop: scale(38),
  },
  homeAddress: {
    marginTop: scale(32),
  },
  buttonLinear: {
    marginTop: scale(24),
  },
  genders: {
    marginTop: scale(24),
    ...Theme.flexRow,
    ...Theme.center,
  },
  headerBackground: {
    flex: 1,
  },
});
