import React from 'react';
import { Contacts as ContactsInput } from './form';

function Contacts() {
  return (
    <>
      <ContactsInput/>
    </>
  );
}

export default Contacts;