import AccountInfo from '../pages/AccountInfo';
import Activate from '../pages/Activate';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import Login from '../pages/Login';
import Register from '../pages/Register';
import Account from '../pages/Account';
import DeleteAccount from '../pages/DeleteAccount/DeleteAccount';
import Settings from '../pages/Settings/Settings';
import ChangePassword from '../pages/ChangePassword/ChangePassword';

const linking = {
  Account: 'account',
  AccountDelete: 'account/data',
  Activate: 'activate',
  AccountChangePassword: 'change-password',
  DeleteAccount: 'delete-account',
  ForgotPassword: 'forgot-password',
  ResetPassword: 'reset-password',
  Login: 'login',
  Register: 'register',
  AccountSettings: 'account/settings',
  Cards: 'cards',
  Card: 'cards/:id',
};

const stacks = [
  ['Account', Account, 'auth'],
  ['AccountChangePassword', ChangePassword, 'auth'],
  ['AccountData', AccountInfo, 'auth'],
  ['AccountDelete', DeleteAccount, 'auth'],
  ['Activate', Activate, 'public'],
  ['ForgotPassword', ForgotPassword, 'public'],
  ['ResetPassword', ResetPassword, 'public'],
  ['Login', Login, 'public'],
  ['Register', Register, 'public'],
  ['AccountSettings', Settings, 'auth'],
  ['Cards', Settings, 'auth', , { title: 'Karten' }],
  ['Card', Settings, 'auth', ({ params }) => params.id],
];

export default {
  linking,
  stacks,
};