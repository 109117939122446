import React, { memo, useState, useCallback } from 'react';
import { View, StyleSheet, ScrollView } from 'react-native';
import { Colors, Routes } from '@notfallkarte/app-ui/src/configs';
import Greeting from '@notfallkarte/app-ui/src/components/Home/Greeting';
import SearchBox from '@notfallkarte/app-ui/src/elements/SearchBox';
import MainControl from '@notfallkarte/app-ui/src/components/Home/MainControl2';
import TasksForToday from '@notfallkarte/app-ui/src/components/Home/TasksForToday';
import { getStatusBarHeight } from 'react-native-iphone-x-helper';
import { useNavigation } from '@react-navigation/native';
import IconNotification from '@notfallkarte/app-ui/src/components/Home/IconNotification';
import scale from '@notfallkarte/app-ui/src/utils/scale';
import Layout from '@notfallkarte/app-ui/src/elements/Layout/Container';
import Theme from '@notfallkarte/app-ui/src/style/Theme';
import { useTheme } from '@notfallkarte/app-ui/src/configs/ChangeTheme';
import Public from '../../components/Public';

const Home = memo((props) => {
  const [searchKey, setSearchKey] = useState('');
  const { navigate } = useNavigation();
  const onTodayTask = useCallback(() => {
    navigate(Routes.TodayTask);
  }, [navigate]);
  const { theme } = useTheme();
  return (
    <Layout style={styles.container}>
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={styles.contentContainerStyle}
      >
        <TasksForToday step={5} onPress={onTodayTask} />
        <Public/>
        <MainControl />
      </ScrollView>
    </Layout>
  );
});

export default Home;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainerStyle: {
    paddingBottom: 16,
    paddingHorizontal: 24,
    paddingTop: getStatusBarHeight() + 16,
  },
  notification: {
    position: 'absolute',
    top: getStatusBarHeight() + 16,
    right: 24,
    zIndex: 10,
  },
  searchBox: {
    width: scale(327),
    height: scale(48),
    ...Theme.shadow,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: scale(42, true),
    marginBottom: scale(40, true),
  },
});