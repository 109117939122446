import routes from '../routes';

const init = (core) => {
};

const getRoutes = (core) => {
  return routes;
};

export const plugin = {
  init,
  getRoutes,
};