import React from 'react';
import { DateInput, TextInput } from './form';

function Personal() {
  return (
    <>
      <TextInput name="name" label="Name" />
      <TextInput name="dob" label="Geburtsdatum" />
      <TextInput name="height" label="Größe" />
      <TextInput name="weight" label="Gewicht" />
    </>
  );
}

export default Personal;