import React, {memo} from 'react';
import {View, StyleSheet} from 'react-native';
import Theme from '../../../style/Theme';
import {Routes} from '../../../configs';
import FeatureItem from '../../../components/FeatureItem';
import {IMAGE} from '../../../images/Image';
import Container from '../../../elements/Layout/Container';
import { useDispatch, useSelector } from 'react-redux'
import { authService } from '@notfallkarte/app-account/services';
import { navigate } from '@notfallkarte/app-core/utils/RootNavigation';
import { pages } from '@notfallkarte/app-data/config/data';

const MainControl = memo(() => {
  const mainFeature = [
    {
      img: IMAGE.askFreeQuestion,
      title: 'Notfallseite',
      route: 'Data',
    },
    {
      img: IMAGE.healthGuide,
      title: 'Rezept-Verwaltung',
      route: 'Data',
    },
    {
      img: IMAGE.healthFeed,
      title: 'Benutzerkonto',
      route: 'Account',
    },
    {
      img: IMAGE.consultMange,
      title: 'Abmelden',
      route: 'Account',
    },
    {
      img: IMAGE.consultMange,
      title: 'Abmelden',
      route: 'Account',
    },
  ];

  return (
    <Container style={styles.container}>
      {pages.map((entry, index) => (
        <FeatureItem img={mainFeature[index].img} title={entry[2]} route='Entry' routeOptions={{ id: entry[0] }} key={index} />
      ))}
    </Container>
  );
});

export default MainControl;

const styles = StyleSheet.create({
  container: {
    ...Theme.flexDirection,
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginTop: 45,
  },
});
