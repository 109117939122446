import React from 'react';
import { Button } from 'native-base';
import { useFieldArray } from 'react-hook-form';
import Contact from './Contact';

function Contacts() {
  const {
    fields,
    append,
    update,
    remove,
  } = useFieldArray({ name: 'contacts' });

  const onAdd = () => {
    append({});
  };

  const onDelete = (index) => {
    remove(index);
  };

  const onChange = (index, value) => {
    update(index, value);
  };

  return (
    <>
      {fields.map((contact, index) => (
        <Contact key={contact.id} name={`contact${index + 1}`} label={`Kontakt ${index + 1}`} value={contact} onDelete={() => onDelete(index)} onChange={(value) => onChange(index, value)} />
      ))}
      <Button onPress={onAdd}>Hinzufügen</Button>
    </>
  );
}

export default Contacts;