import routes from '../routes';

const core = {};

core.modules = [];

const initModules = () => {
  core.modules.forEach(module => {
    module.init?.(core);
  });
};

core.init = (modules) => {
  core.modules = modules;

  initModules();
};

core.getRoutes = (modules) => {
  const linking = {};
  var stacks = [];

  Object.assign(linking, ...modules.map(module => module.getRoutes?.(core).linking).concat(routes.linking));

  stacks = modules.map(module => module.getRoutes?.(core).stacks).flat().concat(routes.stacks);

  return { linking, stacks };
};

export default core;