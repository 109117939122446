import React from 'react';
import { StyleSheet } from 'react-native';
import { FormControl, Input, Text } from 'native-base';
import { useFormContext, Controller } from 'react-hook-form';

function Document({ name, label }) {
  const { control, formState: { errors } } = useFormContext();

  return (
    <>
      <Text style={styles.label}>
        {label}
      </Text>
      <FormControl isRequired>
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              onBlur={onBlur}
              onChangeText={(val) => onChange(val)}
              value={value}
            />
          )}
          name={name}
          defaultValue=""
        />
        <FormControl.ErrorMessage>
          {errors[name]?.message}
        </FormControl.ErrorMessage>
      </FormControl>
    </>
  );
}

const styles = StyleSheet.create({
  label: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
    marginVertical: '0.5em',
    textAlign: 'left',
  },
});

export default Document;