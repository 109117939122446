import React, { memo } from 'react';
import { View, StyleSheet, Image } from 'react-native';
import Theme from '../../../style/Theme';
import Text from '../../../elements/Text';
import { AVATAR } from '../../../images/Avatar';
import { useSelector } from 'react-redux'
import scale from '../../../utils/scale';
import { ICON } from '@notfallkarte/app-ui/src/images/Icon';

const Greeting = memo((props) => {
  const state = useSelector((state) => state);

  return (
    <View style={styles.container}>
      {/*
      <View style={{ flexBasis: 0, minWidth: 50 }}>
        <Image
          source={AVATAR.avatar2}
          style={{ width: 50, height: 50, borderRadius: '50%' }}
        />
      </View>
      */}
      <View style={{ flexGrow: 1, alignItems: 'center' }}>
        <Image
          source="/logo-blau.png"
          style={{ width: 150, height: 30 }}
        />
      </View>
      {/*
      <View style={{ alignItems: 'end', flexBasis: 0, minWidth: 50 }}>
        <View style={{ alignItems: 'center', justifyContent: 'center', width: 40, height: 40, backgroundColor: '#0094dc', borderRadius: '10px' }}>
          <Image
            source={ICON.add}
            style={{ width: 25, height: 25, filter: 'grayscale(1) brightness(100)' }}
          />
        </View>
      </View>
      */}
      {/*
      <View style={{ justifyContent: 'space-between', height: 40 }}>
        <Text size={17} lineHeight={37} bold>
          Hallo {state.user.name}
        </Text>
      </View>
      */}
    </View>
  );
});

export default Greeting;

const styles = StyleSheet.create({
  container: {
    ...Theme.flexRow,
    marginBottom: scale(30),
  },
});
