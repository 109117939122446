import React from 'react';
import { StyleSheet } from 'react-native';
import { Flex, Box, VStack, HStack } from 'native-base';

function SwitchGroup({ children }) {
  return (
    <Flex flexDirection="row" flexWrap="wrap">
      {children.map(child => (
        <Box style={styles.element}>
          {child}
        </Box>
      ))}
    </Flex>
  );
}

const styles = StyleSheet.create({
  element: {
    flexBasis: '33.333%',
  },
});

export default SwitchGroup;