import appAccount from '@notfallkarte/app-account';
import appBlog from '@notfallkarte/app-blog';
import appChat from '@notfallkarte/app-chat';
import appData from '@notfallkarte/app-data';
import appHistory from '@notfallkarte/app-history';

const modules = [
  appAccount,
  appBlog,
  appChat,
  appData,
  appHistory,
];

export default modules;