import React, { memo, useCallback } from 'react';
import { View, StyleSheet } from 'react-native';
import LinearColors from '@notfallkarte/app-ui/src/elements/LinearColors';
import { Colors, Constants, Routes } from '@notfallkarte/app-ui/src/configs';
import OnboardingPage from '@notfallkarte/app-ui/src/components/OnBoarding/OnBoardingPage';
import ButtonText from '@notfallkarte/app-ui/src/elements/Buttons/ButtonText';
import Text from '@notfallkarte/app-ui/src/elements/Text';
import {
  getBottomSpace,
  getStatusBarHeight,
} from 'react-native-iphone-x-helper';
import { useNavigation } from '@react-navigation/native';
import { ONBOARDING } from '@notfallkarte/app-ui/src/configs/Data';
import { Image } from 'react-native-web';

const OnBoarding = memo(() => {
  const { navigate } = useNavigation();
  const onLogin = useCallback(() => {
    navigate('Login');
  }, [navigate]);
  const onSignUp = useCallback(() => {
    navigate('Register');
  }, [navigate]);

  return (
    <View style={styles.container}>
      <View style={{position: 'absolute', left: 0, top: 20, width: '100%', zIndex: 10, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Image
          source="/logo-blau.png"
          style={{ width: 150, height: 30 }}
        />
      </View>
      <LinearColors
        style={StyleSheet.absoluteFillObject}
        colors={[Colors.TealBlue, Colors.TurquoiseBlue]}
      >
        {ONBOARDING.map((i, index) => (
          <OnboardingPage
            {...i}
            key={i.id.toString()}
            isFirstItem={index === 0}
            isLastItem={index === ONBOARDING.length - 1}
          />
        ))}
        <ButtonText
          white
          title={'Anmelden'}
          style={styles.loginButton}
          titleColor={Colors.White}
          textProps={{ bold: true }}
          onPress={onLogin}
          backgroundColor="#002060"
        />
        <ButtonText
          white
          backgroundColor="#0095db"
          title={'Registrieren'}
          style={styles.signUpButton}
          titleColor={Colors.White}
          textProps={{ bold: true }}
          onPress={onSignUp}
        />
      </LinearColors>
    </View>
  );
});

export default OnBoarding;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 24,
    paddingBottom: getBottomSpace(),
    paddingTop: getStatusBarHeight(),
    backgroundColor: Colors.DodgerBlue,
  },
  loginButton: {
    width: (Constants.width - 88) / 2,
    height: 50,
    position: 'absolute',
    bottom: (Constants.height / 812) * 77 + getBottomSpace(),
    left: 32,
    borderColor: "#002060",
    borderWidth: 1,
  },
  signUpButton: {
    width: (Constants.width - 88) / 2,
    height: 50,
    position: 'absolute',
    bottom: (Constants.height / 812) * 77 + getBottomSpace(),
    right: 32,
    borderColor: "#0095db",
    borderWidth: 1,
  },
  changeApp: {
    position: 'absolute',
    bottom: 16 + getBottomSpace(),
    alignSelf: 'center',
  },
});