import Menu from '../pages/Menu';
import Details from '../pages/Details';
import Files from '../pages/Files';

const linking = {
  Data: 'data',
  Entry: 'data/:id',
};

const stacks = [
  ['Data', Menu, 'auth', , { title: 'Notfallseite' }],
  ['Entry', Details, 'auth', ({ params }) => params.id],
  // ['Files', Files, 'auth'],
];

export default {
  linking,
  stacks,
};