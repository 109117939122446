import React, {memo, useState, useCallback} from 'react';
import {View, StyleSheet, ScrollView, Image} from 'react-native';
import Text from '@notfallkarte/app-ui/src/elements/Text';
import Theme from '@notfallkarte/app-ui/src/style/Theme';
import scale from '@notfallkarte/app-ui/src/utils/scale';
import {Routes} from '@notfallkarte/app-ui/src/configs';
import InputApp from '@notfallkarte/app-ui/src/elements/InputApp';
import ButtonLinear from '@notfallkarte/app-ui/src/elements/Buttons/ButtonLinear';
import {useNavigation} from '@react-navigation/native';
import AvatarProfile from '@notfallkarte/app-ui/src/components/UpdateProfile/BasicInformation/AvatarProfile';
import {useLayoutEffect} from 'react';
import ButtonIconHeader from '@notfallkarte/app-ui/src/elements/Buttons/ButtonIconHeader';
import Colors from '@notfallkarte/app-ui/src/configs/Colors';
import {ICON} from '@notfallkarte/app-ui/src/images/Icon';
import Layout from '@notfallkarte/app-ui/src/elements/Layout/Layout';
import {useTheme} from '@notfallkarte/app-ui/src/configs/ChangeTheme';
import Container from '@notfallkarte/app-ui/src/elements/Layout/Container';
import { useSelector } from 'react-redux'

const BasicInformation = memo(() => {
  const state = useSelector((state) => state);
  const [name, setName] = useState(state.user.name);
  const [email, setEmail] = useState(state.user.email);

  const {navigate, setOptions} = useNavigation();
  const {theme} = useTheme();
  const onGoToOtherInfo = useCallback(() => {
    navigate(Routes.OtherInformation);
  }, [navigate]);
  const onUploadAvatar = useCallback(() => {}, []);
  useLayoutEffect(() => {
    setOptions({
      title: null,
      headerBackground: () => (
        <Container
          style={[styles.headerBackground, {backgroundColor: theme.background}]}
        />
      ),
      headerLeft: () => (
        <ButtonIconHeader marginLeft={24} tintColor={theme.activeTincolor} />
      ),
    });
  }, [setOptions]);
  return (
    <Container style={styles.container}>
      <ScrollView showsVerticalScrollIndicator={false}>
        <Text
          size={scale(24)}
          lineHeight={scale(28)}
          bold
          marginTop={scale(16)}>
          Persönliche Daten
        </Text>
        <Text size={scale(13)} lineHeight={scale(22)} marginTop={scale(16)}>
          Update your profile to get better the answer from
          {'\n'}doctor.
        </Text>
        <AvatarProfile onPress={onUploadAvatar} />
        <InputApp
          title={'Name'}
          marginTop={scale(38)}
          value={name}
          onChangeText={setName}
        />
        <InputApp
          title={'E-Mail'}
          marginTop={scale(24)}
          value={email}
          onChangeText={setEmail}
        />
        <ButtonLinear
          white
          title={'Speichern'}
          onPress={onGoToOtherInfo}
          style={styles.buttonLinear}
        />
      </ScrollView>
    </Container>
  );
});

export default BasicInformation;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 24,
  },
  buttonChildren: {
    ...Theme.icons,
    marginLeft: scale(8),
  },
  inputApp: {
    marginTop: scale(24),
  },
  firstName: {
    marginTop: scale(38),
  },
  homeAddress: {
    marginTop: scale(32),
  },
  buttonLinear: {
    marginTop: scale(24),
  },
  genders: {
    marginTop: scale(24),
    ...Theme.flexRow,
    ...Theme.center,
  },
  headerBackground: {
    flex: 1,
  },
});
