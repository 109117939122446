import Colors from './Colors';
import React, { useContext } from 'react';

export const themes = {
  dark: {
    background: Colors.Dark,
    backgroundItem: Colors.Beluga,
    text: Colors.WhiteText,
    text_placeholder: Colors.Placeholder,
    activeBackgroundColor: Colors.TealBlue,
    activeTincolor: Colors.WhiteSmoke,
    inactiveBackgroundColor: Colors.Platinum,
    borderColor: Colors.BorderColor,
  },
  light: {
    background: Colors.Snow,
    backgroundItem: Colors.White,
    text: Colors.DarkJungleGreen,
    text_placeholder: Colors.Platinum,
    activeTincolor: Colors.Dark,
    inactiveBackgroundColor: Colors.DarkJungleGreen,
    activeBackgroundColor: Colors.TealBlue,
    borderColor: Colors.Platinum,
  },
};

export const ThemeContext = React.createContext({
  theme: themes.light,
  toggleTheme: () => {},
});

export const useTheme = () => {
  const { theme, toggleTheme } = useContext(ThemeContext);
  return {
    theme,
    toggleTheme,
  };
};