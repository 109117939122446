import React from 'react';
import { StyleSheet } from 'react-native';
import { FormControl, Input, Text, Button } from 'native-base';

function Contact({ name, label, onDelete, value, onChange }) {
  return (
    <>
      <Text style={styles.label}>
        {label}
      </Text>
      <FormControl isRequired>
        <FormControl.Label>Name</FormControl.Label>
        <Input
          name={`${name}_name`}
          onChangeText={(val) => onChange({ ...value, name: val})}
          value={value.name}
        />
      </FormControl>
      <FormControl isRequired>
        <FormControl.Label>Telefonnummer</FormControl.Label>
        <Input
          name={`${name}_phone`}
          onChangeText={(val) => onChange({ ...value, phone: val})}
          value={value.phone}
        />
      </FormControl>
      <Button onPress={onDelete}>Löschen</Button>
    </>
  );
}

const styles = StyleSheet.create({
  label: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
    marginVertical: '0.5em',
    textAlign: 'left',
  },
});

export default Contact;